import React, { Component } from 'react';
import { connect } from 'react-redux';

import strings from 'resources/locales/Translate';

import InputField from './InputField';
import InputGroup from './InputGroup';
import Dropdown from './Dropdown';
import TextArea from './TextArea';
import RadioButton from './RadioButton';
import DateInput from './DateInput';
import Checkbox from './Checkbox';
import ZmccRadioButton from './ZMCCRadioButton';

export class FormComponent extends Component {
  onChangeValue = ({ formName, idKey, type, validators, validateOnChange, dateFormat }) => (
    value,
    field,
    fullValue,
  ) => {
    const { formValue, onChange } = this.props;
    let validatedValue = { value, error: null };
    if (validateOnChange || (formValue && formValue[field] && formValue[field].error)) {
      validatedValue = this.validate(value, field, validators);
    }
    onChange({
      values: this.getValues({ field, fullValue, type, validatedValue }),
      field,
      formName,
    });
  };

  getValues = ({ field, fullValue, type, validatedValue }) => {
    if (type === 'dropdown') {
      return {
        [field]: validatedValue.value,
        [`${field}Error`]: validatedValue.error && validatedValue.error.message ? validatedValue.error.message : null,
        [`${field}FullValue`]: fullValue,
      };
    }
    return {
      [field]: validatedValue.value,
      [`${field}Error`]: validatedValue.error && validatedValue.error.message ? validatedValue.error.message : null,
    };
  };

  onBlur = (field, validators, type, validateOnBlur) => () => {
    if (type !== 'date') {
      const { formName, formValue, onChange } = this.props;
      const stateValue = formValue && formValue[field] ? formValue[field] : '';
      let validatedValue = { value: stateValue, error: null };
      validatedValue = this.validate(stateValue, field, validators);
      if (validateOnBlur) {
        onChange({
          values: this.getValues({ field, type, validatedValue }),
          field,
          formName,
        });
      }
    }
  };

  validate = (value, field, validators) => {
    if (validators && validators.length) {
      for (let i = 0; i < validators.length; i++) {
        let error = validators[i].check(value, validators[i].message, validators[i].minDate);
        if (error) {
          return { value, error };
        }
      }
    }
    return { value, error: null };
  };

  renderText = (item, key) => {
    const { disableForm, formName, formValue } = this.props;
    const label = item.label === 'loanValue' ? `${strings[item.label]} [${item.currency && item.currency.split('-')[0].trim()}]` : strings[item.label];
    return (
      <div key={key} hidden={item.isDisplayed} className={`form-group ${item.styleClass}`}>
        <InputField
          dataTest={item.dataTest}
          field={item.field}
          label={label}
          type={item.type}
          validators={item.validators}
          isRequired={item.required}
          extraProps={item.extraProps}
          error={formValue && formValue[`${item.field}Error`]}
          value={formValue && formValue[item.field]}
          placeholder={strings[item.placeholder]}
          showExtraInfo={item.showExtraInfo}
          infoMessage={item.infoMessage}
          icon={item.icon}
          disabled={disableForm ? disableForm : item.disabled}
          onChange={this.onChangeValue({ formName, type: item.type, validators: item.validators })}
          onBlur={this.onBlur(item.field, item.validators, item.type, item.validateOnBlur)}
          bigSizeInput={item.bigSizeInput}
          showRedAsterik={item.showRedAsterik}
          hideOptionalText={item.hideOptionalText}
        />
      </div>
    );
  };

  renderInputGroup = (item, key) => {
    const { disableForm, formName, formValue } = this.props;
    return (
      <div key={key} className={`form-group ${item.styleClass}`}>
        <InputGroup
          dataTest={item.dataTest}
          label={strings[item.label]}
          type={item.type}
          field={item.field}
          validators={item.validators}
          isRequired={item.required}
          extraProps={item.extraProps}
          placeholder={strings[item.placeholder]}
          inputGroupText={item.isAppend ? strings[item.inputGroupText] : item.inputGroupText}
          showExtraInfo={item.showExtraInfo}
          infoMessage={item.infoMessage}
          icon={item.icon}
          error={formValue && formValue[`${item.field}Error`]}
          value={formValue && formValue[item.field]}
          disabled={disableForm ? disableForm : item.disabled}
          onChange={this.onChangeValue({ formName, type: item.type, validators: item.validators })}
          onBlur={this.onBlur(item.field, item.validators, item.type, item.validateOnBlur)}
          isAppend={item.isAppend}
          showRedAsterik={item.showRedAsterik}
          hideOptionalText={item.hideOptionalText}
          neweVersionStyle={item.neweVersionStyle}
        />
      </div>
    );
  };

  renderTextArea = (item, key) => {
    const { disableForm, formName, formValue } = this.props;
    return (
      <div key={key} className={`form-group${item.styleClass && ` ${item.styleClass}`}`}>
        <TextArea
          dataTest={item.dataTest}
          disabled={disableForm ? disableForm : item.disabled}
          label={strings[item.label]}
          type={item.type}
          field={item.field}
          isRequired={item.required}
          extraProps={item.extraProps}
          error={formValue && formValue[`${item.field}Error`]}
          value={formValue && formValue[item.field]}
          placeholder={strings[item.placeholder]}
          showExtraInfo={item.showExtraInfo}
          infoMessage={item.infoMessage}
          onChange={this.onChangeValue({ formName, type: item.type, validators: item.validators })}
          onBlur={this.onBlur(item.field, item.validators, item.type, item.validateOnBlur)}
          showRedAsterik={item.showRedAsterik}
          hideOptionalText={item.hideOptionalText}
          rows={item.rows}
        />
      </div>
    );
  };

  renderCheckbox = (item, key) => {
    const { disableForm, formName, formValue } = this.props;
    return (
      <div key={key} className={`form-group ${item.styleClass}`}>
        <Checkbox
          dataTest={item.dataTest}
          field={item.field}
          label={strings[item.label] || item.label}
          selected={formValue && formValue[item.field]}
          disabled={disableForm ? disableForm : item.disabled}
          onChange={this.onChangeValue({ formName, type: item.type, validators: item.validators })}
          showRedAsterik={item.showRedAsterik}
          hideOptionalText={item.hideOptionalText}
          newuiVersionStyle={item.newuiVersionStyle}
        />
      </div>
    );
  };

  renderDropDown = (item, key) => {
    const { disableForm, formName, formValue } = this.props;
    const itemValue = formValue && formValue[item.field] && formValue[item.field];
    let fullValue = formValue && formValue[`${item.field}FullValue`];
    if (!fullValue && item.options && item.options.length) {
      fullValue = item.options.filter(d => d[item.idKey] === itemValue);
      fullValue = fullValue && fullValue.length && fullValue[0];
    }
    return (
      <div key={key} hidden={item.isDisplayed} className={`form-group ${item.styleClass}`}>
        <Dropdown
          key={key}
          dataTest={item.dataTest}
          data={item.options}
          onChange={this.onChangeValue({
            formName,
            idKey: item.idKey,
            type: item.type,
            validators: item.validators,
            validateOnChange: item.validateOnChange,
          })}
          value={itemValue}
          error={formValue && formValue[`${item.field}Error`]}
          fullValue={fullValue}
          hasSection={item.hasSection}
          multiSelect={item.multiSelect}
          filter={item.filter}
          fetchValueFrom={item.fetchValueFrom}
          isRequired={item.required}
          placeholder={strings[item.placeholder]}
          label={strings[item.label]}
          field={item.field}
          idKey={item.idKey}
          displayValue={item.displayValue}
          validators={item.validators}
          disabled={disableForm ? disableForm : item.disabled}
          excludeData={item.excludeData}
          optionsArrayKey={item.optionsArrayKey}
          titleDisplay={item.titleDisplay}
          showExtraInfo={item.showExtraInfo}
          infoMessage={item.infoMessage}
          selectAllPlaceholder={item.selectAllPlaceholder}
          selectAllOption={item.selectAllOption}
          showRedAsterik={item.showRedAsterik}
          hideOptionalText={item.hideOptionalText}
          isInfoHasAttributes={item.isInfoHasAttributes}
        />
      </div>
    );
  };

  renderRadioButton = (item, key) => {
    const { disableForm, formName, formValue } = this.props;
    return (
      <div key={key} className={`form-group ${item.styleClass}`}>
        <RadioButton
          dataTest={item.dataTest}
          data={item.options}
          disabled={disableForm ? disableForm : item.disabled}
          field={item.field}
          label={strings[item.label]}
          validators={item.validators}
          isRequired={item.required}
          showExtraInfo={item.showExtraInfo}
          infoMessage={item.infoMessage}
          onError={this.handleInputFieldError}
          onChange={this.onChangeValue({ formName, idKey: item.idKey, type: item.type, validators: item.validators })}
          error={formValue && formValue[`${item.field}Error`]}
          value={formValue && formValue[item.field]}
          showRedAsterik={item.showRedAsterik}
          hideOptionalText={item.hideOptionalText}
          showClearIcon={item.showClearIcon}
        />
      </div>
    );
  };

  renderZmccRadioButton = (item, key) => {
    const { disableForm, formName, formValue } = this.props;
    return (
      <div key={key} className={`form-group ${item.styleClass}`}>
        <ZmccRadioButton
          dataTest={item.dataTest}
          data={item.options}
          disabled={disableForm ? disableForm : item.disabled}
          field={item.field}
          label={strings[item.label]}
          validators={item.validators}
          isRequired={item.required}
          showExtraInfo={item.showExtraInfo}
          infoMessage={item.infoMessage}
          onError={this.handleInputFieldError}
          onChange={this.onChangeValue({ formName, idKey: item.idKey, type: item.type, validators: item.validators })}
          error={formValue && formValue[`${item.field}Error`]}
          value={formValue && formValue[item.field]}
          showRedAsterik={item.showRedAsterik}
          hideOptionalText={item.hideOptionalText}
        />
      </div>
    );
  };

  renderDate = (item, key) => {
    const { disableForm, formName, formValue, language } = this.props;
    return (
      <div key={key} className={`form-group ${item.styleClass}`}>
        <DateInput
          dataTest={item.dataTest}
          disabled={disableForm ? disableForm : !!item.disabled}
          field={item.field}
          placeholder={strings[item.placeholder]}
          label={strings[item.label] || item.label}
          additionalLabel={item.additionalLabel}
          footerLabel={item.footerLabel}
          maxDate={item.maxDate}
          minDate={item.minDate}
          mainContainerStyle={item.mainContainerStyle}
          locale={language.locale}
          selectedDate={formValue && formValue[item.field]}
          validators={item.validators}
          isRequired={item.required}
          hideOptionalText={item.hideOptionalText}
          showExtraInfo={item.showExtraInfo}
          infoMessage={item.infoMessage}
          dateFormat={item.dateFormat}
          minDetail={item.minDetail}
          maxDetail={item.maxDetail}
          isClearable={item.isClearable}
          {...item.extraProps}
          onChange={this.onChangeValue({
            formName,
            idKey: item.idKey,
            type: item.type,
            validators: item.validators,
            validateOnChange: item.validateOnChange,
          })}
          onBlur={this.onBlur(item.field, item.validators, item.type, item.validateOnBlur)}
          onError={this.handleInputFieldError}
          activeStartDate={item.activeStartDate}
          isOpenDateModal={item.isOpenDateModal}
          onClick={this.props.onClick ? e => this.props.onClick(e, item) : null}
          error={formValue && formValue[`${item.field}Error`]}
          disabledDates={item.tileDisabled}
          momentDateFormat={item.momentDateFormat}
          showRedAsterik={item.showRedAsterik}
        />
      </div>
    );
  };

  renderForm = () => {
    const { model } = this.props;
    let arrayOfFields = [];
    model.forEach((item, key) => {
      switch (item.type) {
        case 'text':
        case 'email':
        case 'number':
          arrayOfFields.push(this.renderText(item, key));
          break;
        case 'inputgroup':
          arrayOfFields.push(this.renderInputGroup(item, key));
          break;
        case 'textarea':
          arrayOfFields.push(this.renderTextArea(item, key));
          break;
        case 'dropdown':
          arrayOfFields.push(this.renderDropDown(item, key));
          break;
        case 'radio-button':
          arrayOfFields.push(this.renderRadioButton(item, key));
          break;
        case 'checkbox':
          arrayOfFields.push(this.renderCheckbox(item, key));
          break;
        case 'date':
          arrayOfFields.push(this.renderDate(item, key));
          break;
          case 'enhanced-RadioButton':
            arrayOfFields.push(this.renderZmccRadioButton(item, key));
            break;
        default:
          break;
      }
    });
    return arrayOfFields;
  };

  render() {
    const { className, dataTest, rowStyle } = this.props;
    return (
      <div
        className={`form-component-container${className ? ` ${className}` : ''}`}
        data-test={dataTest || 'form-component-container'}
      >
        <div className={`row${rowStyle ? ` ${rowStyle}` : ''}`}>{this.renderForm()}</div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.languageReducer,
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(FormComponent);
