import React from 'react';
import Icon from '../../../../../../library/common/commonComponents/Icon/Icon';
import InputField from 'library/common/commonComponents/FormComponent/Optimized/InputField';
import Validators from 'library/utilities/Validators';
import strings from 'resources/locales/Translate';

export const AddEmailAddress = ({
  onChange,
  onAdd,
  newEmailAddress,
  emailAdress,
  handleOnChangeExstingValue,
  error,
  deleteEmail,
  disabled,
  newCcEmailAddressArray,
  ccEmailAddress,
  ccEmailError
}) => {
  const disableCcMali = newCcEmailAddressArray && newCcEmailAddressArray.length >=5
  return (
    <div className='pickup-request-email col-md-6 p-0'>
      <div className='col-md-6 p-0'>
        {newEmailAddress &&
          newEmailAddress.length > 0 &&
          newEmailAddress.map((item, index) => (
            <>
              <div className='row' style={{ marginBottom: '1%' }}>
                <div className='col-md-10'>
                  <p className='refubishment-email-label'>{strings.email} <span className='mandatory-asterik'>*</span></p>
                  <InputField
                    isRequired={false}
                    onChange={e => handleOnChangeExstingValue(e, index, 'email')}
                    value={`${item.email}`}
                    className='mb-2'
                  />
                </div>

                <button type='button' className='delete-email-button ' onClick={() => deleteEmail(index, 'email')}>
                  <Icon name='delete' width={24} height={24} fill='#0088d0' />
                </button>
              </div>
            </>
          ))}

        <div className='row'>
          <div className=' col-md-10'>
            <p className='refubishment-email-label'>{strings.email} <span className='mandatory-asterik'>*</span></p>
            <InputField
              onChange={e => onChange(e, 'email')}
              value={emailAdress}
              disabled={disabled}
              isRequired={false}
                    validators={[{ check: Validators.required, message: strings.requiredErrorMessage },
                      { check: Validators.email, message: strings.invalidEmailError }
                    ]}
                    error={error}
            />
          </div>

          <button type='button' className='add-email-button ' onClick={() => onAdd('email')} disabled={disabled}>
            <Icon name='plus' width={30} height={24} fill='#fff' />
          </button>
        </div>
      </div>
      <div className='col-md-6 '>
        {newCcEmailAddressArray &&
          newCcEmailAddressArray.length > 0 &&
          newCcEmailAddressArray.map((item, index) => (
            <>
              <div className='row' style={{ marginBottom: '1%' }}>
                <div className='col-md-10'>
                  <InputField
                    onChange={e => handleOnChangeExstingValue(e, index, 'ccEmail')}
                    value={`${item.email}`}
                    className='mb-2'
                    label={strings.ccEmail}
                  />
                </div>

                <button type='button' className='delete-email-button ' onClick={() => deleteEmail(index, 'ccEmail')}>
                  <Icon name='delete' width={24} height={24} fill='#0088d0' />
                </button>
              </div>
            </>
          ))}
        <div className='row'>
          <div className=' col-md-10'>
            <InputField
              isRequired={false}
              onChange={e => onChange(e, 'ccEmail')}
              value={ccEmailAddress}
              disabled={disableCcMali}
              label={strings.ccEmail}
              error={ccEmailError}
            />
          </div>

          <button type='button' className='add-email-button' onClick={() => onAdd('ccEmail')} disabled={disableCcMali}>
            <Icon name='plus' width={30} height={24} fill='#fff' />
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddEmailAddress;
