import Axios from 'axios';
import { fetchFromStorage } from 'library/utilities/storage';
import { identifiers } from 'library/common/commonConstants/IdentifiersConstants';

const language = fetchFromStorage(identifiers.language);
export const exportListRequest = (url, valuesToSend, token) => {
  return Axios(process.env.REACT_APP_BASE_URL + url, {
    method: 'POST',
    responseType: 'blob',
    headers: {
      token: `${token}`,
      'Content-Type': 'application/json',
      'Accept-Language': language,
    },
    data: valuesToSend,
  });
}
