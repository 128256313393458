import React, { PureComponent } from 'react';
import { isEqual } from 'lodash';

import CreateStockActionButtons from 'library/common/commonComponents/CreateStockActionButtons';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import AddMoreUsers from 'library/common/commonComponents/AddMoreUsers/AddMoreUsers';
import { scrollToTop } from 'library/utilities/scrollActions';
import { validateForm } from 'library/utilities/ValidateForm.util';
import strings from 'resources/locales/Translate';

import RequestAccess from './RequestAccess';
import { addUserModel, filterModel } from './Entitlements.constants';
import './Entitlements.style.scss';
import { getllStockRoles, getRMSZMCCStockRoles } from 'library/common/commonConstants/roles';
import { changeLoader } from 'library/common/commonActions/AppActionsActions';

const sortUserByFirstName = users =>
  users.sort((a, b) => (a.lastName > b.lastName ? 1 : a.lastName < b.lastName ? -1 : 0));

const sortEntitlements = entitlements =>
  entitlements.sort((a, b) =>
    a.userFullValue.lastName > b.userFullValue.lastName
      ? 1
      : a.userFullValue.lastName < b.userFullValue.lastName
      ? -1
      : 0,
  );

const generateUsers = (entitlements, users, roles) => {
  const formsArrayToDisplay = [];
  const userIds = [];
  entitlements.forEach(item => {
    const user = users && users.find(userItem => userItem.accountId === item.accountId);
    const role = roles && roles.find(roleItem => roleItem.id === item.roleId);
    if (user) {
      userIds.push(user.accountId);
      formsArrayToDisplay.push({
        ...item,
        id: item.id,
        accountId: item.accountId,
        user: user.accountId,
        userFullValue: user,
        role: item.roleId,
        roleFullValue: role,
      });
    }
  });

  return { formsArrayToDisplay: sortEntitlements(formsArrayToDisplay), userIds };
};

const generateRequest = (data, users, roles) => {
  const newData = [];
  data.forEach(item => {
    const user = users && users.find(userItem => userItem.accountId === item.accountId);
    const role = roles && roles.find(roleItem => roleItem.id === item.roleId);
    newData.push({
      ...item,
      id: item.id,
      accountId: item.accountId,
      accountIdFullValue: user,
      roleId: item.roleId,
      roleIdFullValue: role,
    });
  });

  return newData;
};

class Entitlements extends PureComponent {
  static getDerivedStateFromProps(props, state) {
    let addedUsers = state.addedUsers;
    let availableUsers = state.availableUsers;
    let roles = state.roles;
    let entitlements = state.entitlements;
    let formsArrayToDisplay = state.formsArrayToDisplay;
    if (!state.roles && props.masterData.rolesList && props.masterData.rolesList.length) {
      roles = props.masterData.rolesList.filter(item => item.priority !== 1);
    }
    if (!state.availableUsers.length && props.masterData.usersList && props.masterData.usersList.length) {
      availableUsers = sortUserByFirstName(props.masterData.usersList);
    }
    if (
      (!state.entitlements || (props.entitlements && !isEqual(state.entitlements, props.entitlements))) &&
      props.entitlements &&
      props.masterData.usersList &&
      props.masterData.usersList.length &&
      props.masterData.rolesList &&
      props.masterData.rolesList.length
    ) {
      entitlements = props.entitlements;
      const { businessUnitFullValue } = props.stockDetails;
      if ((businessUnitFullValue && businessUnitFullValue.designation) === 'RMS_L_L') {
        roles = getllStockRoles();
      }
      if ((businessUnitFullValue && businessUnitFullValue.designation) === 'RMS_ZMCC') {
        roles = getRMSZMCCStockRoles();
      }
      const { formsArrayToDisplay: formArray, userIds } = generateUsers(
        entitlements,
        props.masterData.usersList,
        roles,
      );
      addedUsers = formArray;
      formsArrayToDisplay = sortEntitlements(formArray);
      availableUsers = sortUserByFirstName(
        props.masterData.usersList.filter(user => !userIds.includes(user.accountId)),
      );
    }
    return {
      addedUsers,
      availableUsers,
      entitlements,
      formsArrayToDisplay,
      roles,
    };
  }

  state = {
    availableUsers: [],
    entitlements: null,
    formsArrayToDisplay: null,
    idsToDelete: [],
    itemsToDelete: [],
    filter: {},
    loading: false,
    newRoleData: {},
    requestStockAccess: null,
    roles: null,
  };

  componentDidMount() {
    this.props.getNewRoles(this.props.stockId);
  }
  componentDidUpdate(prevProps) {
    if (!this.props.entitlements && this.props.stockId) {
      this.getEntitlements();
    }
    if (
      !this.state.requestStockAccess &&
      this.props.stockId &&
      this.props.masterData.usersList &&
      this.props.masterData.usersList.length &&
      this.props.masterData.rolesList &&
      this.props.masterData.rolesList.length
    ) {
      this.getRequestStockAccess();
    }
    if (this.props.language !== prevProps.language) {
      this.setState({ loading: true }, async () => {
        const { rolesList } = await this.props.fetchMasterDataOnLanguageChange();
        const entitlements = this.props.entitlements;
        const { formsArrayToDisplay } = generateUsers(entitlements, this.props.masterData.usersList);
        this.setState({
          loading: false,
          entitlements,
          formsArrayToDisplay,
          roles: rolesList && rolesList.filter(item => item.priority !== 1),
        });
      });
      this.props.getNewRoles(this.props.stockId);
    }
  }

  getEntitlements = () => {
    if (!this.state.loading) {
      this.setState({ loading: true }, async () => {
        this.props.fetchStockEntitlements(this.props.stockId, true);
      });
    }
  };

  getRequestStockAccess = () => {
    if (!this.state.loading) {
      let roles = this.props.masterData.rolesList;
      const { businessUnitFullValue } = this.props.stockDetails;
      if ((businessUnitFullValue && businessUnitFullValue.designation) === 'RMS_L_L') {
        roles = getllStockRoles();
      }
      if ((businessUnitFullValue && businessUnitFullValue.designation) === 'RMS_ZMCC') {
        roles = getRMSZMCCStockRoles();
      }
      this.setState({ loading: true }, async () => {
        const { success, data: requestStockAccess } = await this.props.fetchRequestAccess(this.props.stockId);
        if (success) {
          this.setState({
            requestStockAccess: generateRequest(requestStockAccess, this.props.masterData.usersList, roles),
            loading: false,
          });
        }
      });
    }
  };

  handleChangeNew = ({ values, field, formName }) => {
    if (formName === 'newRoleData') {
      this.setState(prevState => ({ newRoleData: { ...prevState.newRoleData, ...values } }));
    }
  };

  handleChange =
    accountId =>
    ({ values, field, formName }) => {
      const formsArrayToDisplay = [...this.state.formsArrayToDisplay];
      const index = formsArrayToDisplay.findIndex(item => item.accountId === accountId);
      formsArrayToDisplay[index] = {
        ...formsArrayToDisplay[index],
        ...values,
      };
      this.setState({ formsArrayToDisplay });
    };

  addRow = () => {
    const { newRoleData } = this.state;
    const validated = validateForm({ form: { formData: newRoleData }, model: this.addNewUserModel() });
    if (!validated.isFormValid) {
      this.setState({ newRoleData: validated.formData });
    } else {
      const availableUsers = [...this.state.availableUsers].filter(user => user.accountId !== newRoleData.user);

      const idsToDelete = [...this.state.idsToDelete];
      const itemsToDelete = [...this.state.itemsToDelete];
      const deleted = itemsToDelete.find(item => item.user === newRoleData.user);
      if (deleted && deleted.id) {
        const index = idsToDelete.indexOf(deleted.id);
        idsToDelete.splice(index, 1);
        itemsToDelete.splice(index, 1);
      }
      const formsArrayToDisplay = [
        ...this.state.formsArrayToDisplay,
        {
          id: (deleted && deleted.id) || 0,
          ...newRoleData,
          accountId: newRoleData.user,
          stockId: this.props.stockId,
        },
      ];
      this.setState({
        availableUsers: sortUserByFirstName(availableUsers),
        formsArrayToDisplay: sortEntitlements(formsArrayToDisplay),
        idsToDelete,
        itemsToDelete,
        newRoleData: {},
      });
    }
  };

  handleRemove = accountId => () => {
    const availableUsers = [...this.state.availableUsers];
    const idsToDelete = [...this.state.idsToDelete];
    const itemsToDelete = [...this.state.itemsToDelete];
    const formsArrayToDisplay = [...this.state.formsArrayToDisplay];
    const index = formsArrayToDisplay.findIndex(item => item.accountId === accountId);
    const item = formsArrayToDisplay[index];
    formsArrayToDisplay.splice(index, 1);
    availableUsers.push(item.userFullValue);
    if (item.id && !idsToDelete.includes(item.id)) {
      itemsToDelete.push(item);
      idsToDelete.push(item.id);
    }
    this.setState({
      availableUsers: sortUserByFirstName(availableUsers),
      formsArrayToDisplay,
      idsToDelete,
      itemsToDelete,
    });
  };

  handleAcceptReject = index => status => async () => {
    const { requestStockAccess } = this.state;
    const value = requestStockAccess[index];
    const valueToSend = {
      roleId: value.roleId,
      requestAccessStatus: status,
      requestAccessPermissionId: value.requestAccessPermissionId,
    };
    const { success } = await this.props.requestAcceptReject(valueToSend, this.props.stockId);
    if (success) {
      this.getRequestStockAccess();
    }
    scrollToTop(500);
  };

  handleChangeRequest =
    index =>
    ({ values, field }) => {
      const requestStockAccess = [...this.state.requestStockAccess];
      requestStockAccess[index] = {
        ...requestStockAccess[index],
        ...values,
      };
      this.setState({ requestStockAccess });
    };

  handleFilterChange = ({ values, field, formName }) => {
    this.setState(prevState => ({ filter: { ...prevState.filter, ...values } }));
  };

  generateDataForAPI = async () => {
    const {stockDetails, crmAccountId} = this.props;
    const { formsArrayToDisplay, idsToDelete } = this.state;
    const arrayToSend = [];
    if (stockDetails && stockDetails.businessUnitFullValue && stockDetails.businessUnitFullValue.designation === 'RMS_L_L') {
      if (!crmAccountId) {
        scrollToTop(500);
        this.props.toggleActionMessage(true, 'error', 'crmAccountIdError');
        return;
      }
    }
    if (formsArrayToDisplay.length) {
      const validateBeforeSending = this.validateBeforeSending();
      if (!validateBeforeSending.numberOfStockControllers) {
        this.props.toggleActionMessage(true, 'error', 'addAtleastOneStockController');
      } else if (!validateBeforeSending) {
        this.props.toggleActionMessage(true, 'error', 'entitlementAtleastOneShouldBeAdded');
      } else {
        formsArrayToDisplay.forEach(item => {
          const objectToStore = {
            accountId: item.user,
            roleId: item.role,
            id: item.id || 0,
            costCenter: item.costCenter || ""
          };
          arrayToSend.push(objectToStore);
        });
        const { success } = await this.props.createStockRole(arrayToSend, idsToDelete, this.props.stockId);
        if (success) {
          this.setState({ idsToDelete: [], itemsToDelete: [] });
        }else if(success === 0){
          const entitlements = this.props.entitlements;
          const { formsArrayToDisplay } = generateUsers(entitlements, this.props.masterData.usersList);
          this.setState({formsArrayToDisplay, idsToDelete: [], itemsToDelete: []})
        }
      }
    } else {
      this.props.toggleActionMessage(true, 'error', 'entitlementAtleastOneShouldBeAdded');
    }
    scrollToTop(500);
  };

  validateBeforeSending() {
    const { formsArrayToDisplay } = this.state;
    let validated = true;
    let numberOfStockControllers = 0;
    formsArrayToDisplay.forEach(item => {
      if (item.role === '' || item.user === '') {
        validated = false;
      }
      if (item.role === 1) {
        numberOfStockControllers++;
      }
    });
    return { validated, numberOfStockControllers };
  }

  cancelStockCreation = () => {
    this.props.cancelStockCreation();
    this.props.history.replace('/stocks');
  };

  filterModel = () => {
    const model = [...filterModel];
    model[1].options = this.filterRolesForl2l();
    return model;
  };

  filterRolesForl2l = () => {
    const { stockDetails, user, newStockRoles } = this.props;
    const { businessUnitFullValue } = stockDetails;
    const isUserAdmin = user && user.admin;

    if ((businessUnitFullValue && businessUnitFullValue.designation) === 'RMS_L_L' && !isUserAdmin && newStockRoles) {
      return getllStockRoles();
    } 
    else if ((businessUnitFullValue && businessUnitFullValue.designation) === 'RMS_ZMCC' && !isUserAdmin && newStockRoles) {
      return getRMSZMCCStockRoles();
    }
    else {
      if ((businessUnitFullValue && businessUnitFullValue.designation) === 'RMS_L_L') {
        return getllStockRoles();
      } 
      else if ((businessUnitFullValue && businessUnitFullValue.designation) === 'RMS_ZMCC') {
        return getRMSZMCCStockRoles();
      }
      else {
        const newStockRole = newStockRoles.filter(item => item.role !== 'Admin');
        return newStockRole;
      }
    }
  };

  addAllUserModel = () => {
    const model = [];
    if (this.checkActiveProvience()) {
      model.push({ ...addUserModel[0], disabled: true, options: this.props.masterData.usersList, styleClass: 'col-md-4' });
      model.push({ ...addUserModel[1], options: this.filterRolesForl2l(), styleClass: 'col-md-4' });
      model.push({ ...addUserModel[2], isDisplayed: false });
    }else{
      model.push({ ...addUserModel[0], disabled: true, options: this.props.masterData.usersList });
      model.push({ ...addUserModel[1], options: this.filterRolesForl2l() });
      model.push({ ...addUserModel[2], isDisplayed: true });
    }
    
    return model;
  };

  addNewUserModel = () => { 
    const model = [];
    if (this.checkActiveProvience()) {
      model.push({ ...addUserModel[0], disabled: false, options: this.state.availableUsers, styleClass: 'col-md-4', });
      model.push({ ...addUserModel[1], options: this.filterRolesForl2l(), styleClass: 'col-md-4', });
      model.push({ ...addUserModel[2], isDisplayed: false });
    }else{
      model.push({ ...addUserModel[0], disabled: false, options: this.state.availableUsers });
      model.push({ ...addUserModel[1], options: this.filterRolesForl2l() });
      model.push({ ...addUserModel[2], isDisplayed: true });
    }
    
    return model;
  };

  checkActiveProvience = () =>{
    const { masterData, stockDetails } = this.props;
    
    const enableCostCenterField =  masterData?.activeProvinceCountries.includes(stockDetails?.country);
    return enableCostCenterField;
  }

  filterModel = () => {
    const model = [...filterModel];

    model[1].options = this.filterRolesForl2l();
    return model;
  };

  filterData = () => {
    const { formsArrayToDisplay, filter } = this.state;
    const { stockDetails, user } = this.props;
    const { businessUnitFullValue } = stockDetails || {};
    const isUserAdmin = user && user.admin;
    let data = formsArrayToDisplay || [];

    if (data.length && filter && (filter.username || filter.role)) {
      if (filter.username) {
        const username = filter.username.toLowerCase();
        data = data.filter(
          item =>
            item.userFullValue.firstName.toLowerCase().includes(username) ||
            item.userFullValue.lastName.toLowerCase().includes(username),
        );
      }
      if (filter.role) {
        data = data.filter(item => item.role === filter.role);
      }
    }
    return data;
  };

  render() {
    const { filter, newRoleData, requestStockAccess } = this.state;
    const { canEditStock, crmAccountId } = this.props;
    const data = this.filterData();
    return (
      <div className='entitle-container'>
        <div className='container-fluid form-container'>
          <div className='row'>
            <div className='col-12 col-md-6'>
              <h4 className='form-title'>{strings.entitlementIndividuals}</h4>

              <div className='filter-form'>
                <FormComponent model={this.filterModel()} onChange={this.handleFilterChange} formValue={filter} />
              </div>

              <div className='forms'>
                {data.map((form, index) => (
                  <AddMoreUsers
                    key={`${form.id}-${form.accountId}`}
                    index={form.user}
                    value={form}
                    model={this.addAllUserModel()}
                    hideDeleteIcon={!canEditStock}
                    valueAlreadyAdded
                    onRemove={this.handleRemove(form.accountId)}
                    onChange={this.handleChange(form.accountId)}
                  />
                ))}
                {canEditStock && (
                  <div className='form'>
                    <AddMoreUsers
                      key='new'
                      model={this.addNewUserModel()}
                      formName='newRoleData'
                      value={newRoleData}
                      onChange={this.handleChangeNew}
                      onAdd={this.addRow}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className='col-12 col-md-6'>
              {requestStockAccess && !!requestStockAccess.length && (
                <div className='request-access-permission' data-test='request-access-permission'>
                  <h4 className='form-title'>Permissions</h4>
                  {requestStockAccess.map((item, index) => (
                    <RequestAccess
                      key={item.id}
                      canEditStock={canEditStock}
                      value={item}
                      onAcceptReject={this.handleAcceptReject(index)}
                      onChange={this.handleChangeRequest(index)}
                      roles={this.filterRolesForl2l()}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
          {canEditStock && (
            <CreateStockActionButtons onSave={this.generateDataForAPI} onCancel={this.cancelStockCreation} />
          )}
        </div>
      </div>
    );
  }
}

export default Entitlements;
